<!-- //科技管理 -->
<template>
  <div>
    <div class="bottom12">
      <el-row :gutter="20">
        <el-col :span="4" class="flex-row">
          <span class="span">地块名称</span>
          <el-select style="flex: 1" clearable v-model="landId" placeholder="请选择地块名称" @change="landChange">
            <el-option v-for="item in plantLands" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" class="flex-row">
          <span class="span">操作人</span>
          <el-select style="flex: 1" clearable v-model="userId" placeholder="请选择操作人" @change="userChange">
            <el-option v-for="item in sysUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="flex-row">
          <span class="span">巡田时间</span>
          <el-date-picker
            style="flex: 1"
            v-model="searchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-col>
        <el-col :span="2" class="flex-row">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待分配" name="1"></el-tab-pane>
      <el-tab-pane label="已分配" name="2"></el-tab-pane>
    </el-tabs>
    <resTable :state="state" :tableData="tableData" @updateList="getList"></resTable>
    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { allocatedPage, assignedPage } from '@/api/farmWork/api.js';
import { sysUsers, plantLands } from '@/api/api.js';
import dayjs from 'dayjs';
import resTable from './components/resTable.vue';
export default {
  components: {
    resTable
  },
  data() {
    return {
      dialogVisible: false,
      searchDate: [],
      userId: '',
      landId: '',
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: [],
      activeName: '1',
      state: 1,
      sysUsers: [],
      plantLands: []
    };
  },
  created() {
    this.getSysUsers();
    this.getPlantLands();
    this.getList();
  },
  mounted() {},
  methods: {
    getSysUsers() {
      sysUsers({})
        .then((res) => {
          this.sysUsers = res;
        })
        .catch((error) => {});
    },
    getPlantLands() {
      plantLands({})
        .then((res) => {
          this.plantLands = res;
        })
        .catch((error) => {});
    },
    getList() {
      if (this.state == 1) {
        allocatedPage({
          pageNum: this.pageInfo.currentPage,
          pageSize: this.pageInfo.pageSize,
          ...this.searchParams
        })
          .then((res) => {
            // let list = res.rows.map((e) => {
            //   let item = JSON.parse(e.historyFarmingVO);
            //   return { ...e, ...item };
            // });
            this.tableData = res.rows;
            this.pageInfo.totalPage = res.total;
          })
          .catch((error) => {
            this.tableData = [];
          });
      } else {
        assignedPage({
          pageNum: this.pageInfo.currentPage,
          pageSize: this.pageInfo.pageSize,
          ...this.searchParams
        })
          .then((res) => {
            // let list = res.rows.map((e) => {
            //   let item = JSON.parse(e.historyFarmingVO);
            //   return { ...e, ...item };
            // });
            this.tableData = res.rows;
            this.pageInfo.totalPage = res.total;
          })
          .catch((error) => {
            this.tableData = [];
          });
      }
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },

    userChange(val) {
      this.userId = val;
    },
    landChange(val) {
      this.landId = val;
    },
    search() {
      this.searchParams = {
        farmingTypeId: this.farmingTypeId,
        ownerId: this.userId,
        landId: this.landId,
        startDate: this.searchDate && this.searchDate[0] ? dayjs(this.searchDate[0]).format('YYYY-MM-DD') : '',
        endDate: this.searchDate && this.searchDate[1] ? dayjs(this.searchDate[1]).format('YYYY-MM-DD') : ''
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },

    handleClick() {
      if (this.state != this.activeName) {
        this.state = this.activeName;
        this.searchParams = {};
        this.pageInfo.currentPage = 1;
        this.tableData = [];
        this.pageInfo.totalPage = 0;
        this.getList();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span {
    font-size: inherit;
    font-size: 12px;
    width: 60px;
  }
}
.bottom12 {
  margin-bottom: 12px;
}
</style>
