<!-- //科技管理 -->
<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="农事记录" name="1"></el-tab-pane>
      <el-tab-pane label="巡田" name="2"></el-tab-pane>
    </el-tabs>
    <farmList
      :sysUsers="sysUsers"
      :farmingTypes="farmingTypes"
      :farmingNameList="farmingNameList"
      :plantLands="plantLands"
      v-if="activeName == 1"
    ></farmList>
    <patrolList :sysUsers="sysUsers" :plantLands="plantLands" v-if="activeName == 2"></patrolList>
  </div>
</template>

<script>
import patrolList from './patrolList.vue';
import farmList from './farmList.vue';
import { farmingList, sysUsers, farmingTypes, plantLands } from '@/api/api.js';
export default {
  components: {
    farmList,
    patrolList
  },
  data() {
    return {
      sysUsers: [],
      farmingTypes: [],
      farmingNameList: [],
      plantLands: [],
      activeName: '1'
    };
  },
  created() {},
  mounted() {
    this.getSysUsers();
    this.getFarmingTypes();
    this.getFarming();
    this.getPlantLands();
  },
  methods: {
    getSysUsers() {
      sysUsers({})
        .then((res) => {
          this.sysUsers = res;
        })
        .catch((error) => {});
    },
    getFarmingTypes() {
      farmingTypes({})
        .then((res) => {
          this.farmingTypes = res;
        })
        .catch((error) => {});
    },
    getFarming() {
      farmingList()
        .then((res) => {
          this.farmingNameList = res;
        })
        .catch((error) => {});
    },
    getPlantLands() {
      plantLands({})
        .then((res) => {
          this.plantLands = res;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    font-size: inherit;
    font-size: 12px;
    width: 70px;
  }
}
.means-num {
  color: #409eff;
  cursor: pointer;
}
.el-table tbody td .cell:empty::after {
  content: '-';
}
</style>
