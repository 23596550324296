<!-- //科技管理 -->
<template>
  <div>
    <div class="bottom12">
      <el-row :gutter="20">
        <el-col :span="7" class="flex-row">
          <span class="span">农事名称</span>
          <div class="flex-row-dia" style="flex: 1">
            <el-select clearable v-model="farmingStage" placeholder="请选择农事节点" @change="stageChange">
              <el-option v-for="item in farmingTypes" :key="item.stageCode" :label="item.stageName" :value="item.stageCode"> </el-option>
            </el-select>
            <el-select clearable v-model="farmingTypeId" placeholder="请选择农事名称">
              <el-option v-for="item in farmingTypeNames" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4" class="flex-row">
          <span class="span">操作人</span>
          <el-select style="flex: 1" clearable v-model="userId" placeholder="请选择操作人" @change="userChange">
            <el-option v-for="item in sysUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" class="flex-row">
          <span class="span">地块名称</span>
          <el-select style="flex: 1" clearable v-model="landId" placeholder="请选择地块名称" @change="landChange">
            <el-option v-for="item in plantLands" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="6" class="flex-row">
          <span class="span">农事时间</span>
          <el-date-picker
            style="flex: 1;"
            v-model="searchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-col> -->
        <el-col :span="2" class="flex-row">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </div>

    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <el-button type="primary" size="small" @click="(isEdit = false), (dialogVisible = true)">新建农事</el-button>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="farmingTypeName" label="农事名称"> </el-table-column>
      <el-table-column label="农事日期" width="200">
        <template slot-scope="scope">{{ scope.row.startDate | filterDate }} - {{ scope.row.endDate | filterDate }}</template>
      </el-table-column>
      <el-table-column prop="landName" label="地块名称"> </el-table-column>
      <el-table-column label="种植面积">
        <template slot-scope="scope"> {{ formatPlantArea(scope.row.plantArea) }}亩 </template>
      </el-table-column>
      <el-table-column label="作业面积">
        <template slot-scope="scope"> {{ formatPlantArea(scope.row.completedArea) }}亩 </template>
      </el-table-column>
      <el-table-column prop="name" label="农资种类">
        <template slot-scope="scope">
          <span class="means-num" v-if="scope.row.productionRecords && scope.row.productionRecords.length" @click="showRecords(scope.row)">{{
            productionRecordsName(scope.row.productionRecords)
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="农机数量">
        <template slot-scope="scope">
          <span
            v-if="
              (scope.row.machineRecords && scope.row.machineRecords.length) ||
              (scope.row.machineOfOutsideRecords && scope.row.machineOfOutsideRecords.length)
            "
            class="means-num"
            @click="showMachineRecords(scope.row)"
          >
            <template v-if="scope.row.machineRecords && scope.row.machineOfOutsideRecords">
              {{ scope.row.machineRecords.length + scope.row.machineOfOutsideRecords.length }}
            </template>
            <template v-else-if="scope.row.machineRecords && !scope.row.machineOfOutsideRecords">
              {{ scope.row.machineRecords.length }}
            </template>
            <template v-else-if="!scope.row.machineRecords && scope.row.machineOfOutsideRecords">
              {{ scope.row.machineOfOutsideRecords.length }}
            </template>
          </span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column prop="executorName" label="参与人"> </el-table-column>
      <el-table-column prop="operatorName" label="操作人"> </el-table-column>
      <!-- <el-table-column prop="descriptions" show-overflow-tooltip label="备注"> </el-table-column>
      <el-table-column prop="name" label="影像">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.imageUrls && scope.row.imageUrls.length"
            style="width: 100px; height: 100px"
            :src="scope.row.imageUrls[0]"
            :zoom-rate="1.2"
            :preview-src-list="scope.row.imageUrls"
            fit="cover"
          />
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createFarming
      :departmentList="departmentList"
      :sysUsers="sysUsers"
      :farmingTypes="farmingTypes"
      :farmingNameList="farmingNameList"
      :plantLands="plantLands"
      :dialogVisible.sync="dialogVisible"
      :isEdit="isEdit"
      @updateList="getList(1)"
    >
    </createFarming>
    <productRecords
      :records="records"
      :resourceTypeDic="resourceTypeDic"
      :showRecordsFlag.sync="showRecordsFlag"
      v-if="showRecordsFlag"
    ></productRecords>
    <machineRecords :recordsItem="records" :showRecordsFlag.sync="showMachineRecordsFlag" v-if="showMachineRecordsFlag"></machineRecords>
    <!-- <patrolList v-if="activeName == 2"></patrolList> -->
  </div>
</template>

<script>
import createFarming from './components/createFarming.vue';
import productRecords from '@/view/components/productRecords.vue';
import machineRecords from '@/view/components/machineRecords.vue';
import patrolList from './patrolList.vue';
import { farmWorkList } from '@/api/api.js';
import * as utils from '@/utils/utils.js';
import dayjs from 'dayjs';
export default {
  components: {
    createFarming,
    productRecords,
    machineRecords,
    patrolList
  },
  props: {
    sysUsers: [],
    farmingTypes: [],
    farmingNameList: [],
    plantLands: []
  },
  data() {
    return {
      currentItem: {},
      dialogVisible: false,
      isEdit: false,
      searchDate: [],
      departmentList: [],
      farmingTypeNames: [],
      title: '',
      farmingStage: '',
      farmingTypeId: '',
      userId: '',
      landId: '',
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      // activeName: '1',
      tableData: [],
      records: {},
      showRecordsFlag: false,
      showMachineRecordsFlag: false,
      resourceTypeDic: {}
    };
  },
  created() {},
  mounted() {
    this.getList();
    // this.getDepartmentList();
    this.getDicList();
  },
  filters: {
    filterDate(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD');
      }
      return '';
    }
  },
  methods: {
    productionRecordsName(list) {
      let nameList = list.map((e) => {
        return e.agriculturalProductionName;
      });
      let nameStr = nameList.join(',');
      let res = '';
      if (nameStr.length > 5) {
        res = nameStr.substr(0, 4) + '...';
      } else {
        res = nameStr;
      }
      return res;
    },
    formatPlantArea(val) {
      return utils.formatArea(parseFloat(val));
    },
    getList(pageNum) {
      if (pageNum) {
        this.pageInfo.currentPage = pageNum;
      }
      farmWorkList({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        isTask: false,
        ...this.searchParams
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    // getDepartmentList() {
    //   departmentList({}).then(res => {
    //     this.departmentList = res;
    //   });
    // },

    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    stageChange(val) {
      this.farmingNameList.map((item) => {
        if (item.stageCode == val) {
          this.farmingTypeNames = item.farmingTypes;
        }
      });
      // this.farmingTypeNames = this.farmingNameList[val];
      this.farmingTypeId = '';
    },
    userChange(val) {
      this.userId = val;
    },
    landChange(val) {
      this.landId = val;
    },
    search() {
      this.searchParams = {
        farmingTypeId: this.farmingTypeId,
        farmingStage: this.farmingStage,
        ownerId: this.userId,
        landId: this.landId
        // startDate: this.searchDate && this.searchDate[0] ? dayjs(this.searchDate[0]).format('YYYY-MM-DD') : '',
        // endDate: this.searchDate && this.searchDate[1] ? dayjs(this.searchDate[1]).format('YYYY-MM-DD') : ''
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    showRecords(row) {
      if (row.productionRecords && row.productionRecords.length) {
        this.records = row.productionRecords;
        this.showRecordsFlag = true;
      }
    },
    showMachineRecords(row) {
      this.records = row;
      this.showMachineRecordsFlag = true;
    },
    async getDicList() {
      let res = await this.$store.dispatch('getDicAll');

      this.resourceTypeDic = res['agriculture.resource.type'];
    },
    // resourceTypeDic(key) {
    //   return this.resourceTypeDic[key];
    // },
    handleClick() {}
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span {
    font-size: inherit;
    font-size: 12px;
    width: 70px;
  }
}
.means-num {
  color: #409eff;
  cursor: pointer;
}
.bottom12 {
  margin-bottom: 12px;
}
</style>
