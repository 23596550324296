<template>
  <div>
    <el-table
      v-if="tableData && tableData.length"
      :data="tableData"
      stripe
      style="width: 100%"
      header-cell-class-name="header-cell-bg"
      header-row-class-name="header-bg"
    >
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="farmingTypeName" label="巡田时间" width="200">
        <template slot-scope="scope"> {{ scope.row.realBeginDate | filterDate }}-{{ scope.row.realEndDate | filterTime }} </template>
      </el-table-column>
      <el-table-column prop="landName" label="地块名称"> </el-table-column>
      <el-table-column prop="landArea" label="地块面积(亩)"> </el-table-column>

      <el-table-column prop="ownerName" label="操作人"> </el-table-column>

      <el-table-column label="巡田轨迹">
        <template slot-scope="scope">
          <el-button slot="reference" type="text" size="small" @click="toGuiji(scope.row)">查看</el-button>
        </template>
      </el-table-column>

      <el-table-column label="巡田类型">
        <template slot-scope="scope">
          {{
            scope.row.taskType == 'simpleTask'
              ? '普通巡田'
              : scope.row.taskType == 'schemeTask' || scope.row.taskType == 'temporaryTask'
              ? '任务巡田'
              : '-'
          }}
        </template>
      </el-table-column>
      <template v-if="state == 2">
        <el-table-column prop="userName" label="负责人">
          <template slot-scope="scope">
            <div v-if="scope.row.userMessage.businessType == 3">
              <span
                style="color: #409eff; cursor: pointer"
                @click="(taskId = scope.row.userMessage.businessId), (ownerId = scope.row.userMessage.userId), (dialogVisible = true)"
                v-if="state != 2 && scope.row.userMessage && scope.row.userMessage.sendStatus == 1"
                >{{ scope.row.userMessage.userName }}</span
              >
              <span v-else>{{ scope.row.userMessage.userName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="消息状态">
          <template slot-scope="scope">
            {{ scope.row.userMessage && scope.row.userMessage.sendStatus == 1 ? '未发送' : '已发送' }}
          </template>
        </el-table-column>
      </template>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button v-if="state == 1" slot="reference" type="text" size="small" @click="(taskId = scope.row.id), (dialogVisible = true)"
            >分配负责人</el-button
          >
          <el-button
            slot="reference"
            type="text"
            size="small"
            @click="sendToMsg(scope.row.userMessage.id)"
            v-if="state == 2 && scope.row.sendStatus == 1"
            >发送消息</el-button
          >
          <span v-if="state != 1 && state != 2">-</span>
        </template>
      </el-table-column>
    </el-table>
    <patrolDetail :detailId="detailId" :patrolVisible.sync="showDk"></patrolDetail>
    <personList :ownerId="ownerId" :taskId="taskId" :dialogVisible.sync="dialogVisible" @updateList="updateList"></personList>
  </div>
</template>
<script>
import personList from './personList.vue';
import { sendMessage } from '@/api/farmWork/api.js';
import dayjs from 'dayjs';
import patrolDetail from '@/components/patrolDetail';
export default {
  components: {
    personList,
    patrolDetail
  },
  props: {
    state: {
      type: String | Number,
      default: 1
    },
    tableData: []
  },
  data() {
    return {
      showDk: false,
      isEdit: '2',
      dialogVisible: false,
      taskId: '',
      ownerId: '',
      detailId: ''
    };
  },
  filters: {
    filterDate(val) {
      return dayjs(val).format('YYYY.MM.DD HH:mm');
    },
    filterTime(val) {
      return dayjs(val).format('HH:mm');
    }
  },
  methods: {
    toGuiji(item) {
      this.detailId = item.id;
      this.showDk = true;
    },
    updateList() {
      this.$emit('updateList');
    },
    showMsg(msg) {
      this.$message({
        message: msg,
        type: 'success'
      });
      this.updateList();
    },
    sendToMsg(id) {
      sendMessage({ id }).then((res) => {
        this.showMsg('发送成功');
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
