<template>
  <el-dialog title="详情" :visible="dialogVisible" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
    <el-table v-if="tableData.length" ref="multipleTable" :data="tableData" stripe>
      <el-table-column label="选择状态" width="100">
        <template slot-scope="scope">
          <el-radio
            :label="scope.row.id"
            v-model="selId"
            @change="
              (val) => {
                personSelectChange(val, scope.row);
              }
            "
            >&nbsp;</el-radio
          >
        </template>
      </el-table-column>
      <el-table-column prop="name" label="账号"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column label="部门">
        <template slot-scope="scope">{{ scope.row.departmentName || '-' }}</template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="pageInfo.totalPage > 0"
      background
      @current-change="handleSizeChange"
      :current-page.sync="pageInfo.currentPage"
      :page-size="pageInfo.pageSize"
      layout="total, prev, pager, next"
      :total="pageInfo.totalPage"
    >
    </el-pagination>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDg">取 消</el-button>
      <el-button type="primary" :disabled="!selId ? true : false" @click="submitPersonSelect">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { sysUserPage } from '@/api/api';
import { assignResponsible } from '@/api/farmWork/api.js';
export default {
  props: {
    taskId: '',
    dialogVisible: false,
    resourceTypeDic: {},
    ownerId: ''
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getPersonList();
        if (this.ownerId) {
          this.selId = this.ownerId;
        }
      }
    }
  },
  data() {
    return {
      tableData: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      selectedPerson: {},
      selId: ''
    };
  },
  methods: {
    closeDg() {
      this.$emit('update:dialogVisible', false);
    },
    getPersonList() {
      let params = {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize
      };
      sysUserPage(params).then((res) => {
        this.tableData = res.rows;
      });
    },
    personSelectChange(val, row) {
      this.selectedPerson = row;
      this.selId = val;
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getPersonList();
    },
    submitPersonSelect() {
      let params = {
        businessId: this.taskId,
        userId: this.selId
      };
      assignResponsible(params).then((res) => {
        this.showMsg('分配成功');
      });
    },
    showMsg(msg) {
      this.$message({
        message: msg,
        type: 'success'
      });
      this.updateList();
      this.closeDg();
    },
    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style lang="scss" scoped></style>
